<template>
	<v-card
		class="mx-auto shadow-blue mb30"
		:class="{
			pa7: $vuetify.breakpoint.smAndDown,
			pa30: $vuetify.breakpoint.mdAndUp,
		}"
		max-width="100%"
	>
		<span class="ortho-bold pb20">-{{ testTrack.position }}-</span>
		<br />
		<br />
		<span class="question-result-title ortho-bold">{{ testTrack.question }}</span>
		<br />
		<br />
		<span class="question-action">{{ testTrack.instruction }}</span>
		<br />
		<br />
		<v-row v-if="answerUserChoose && answerUserChoose.is_good_answer === true">
			<v-col>
				<span>
					Ta réponse :
					<span class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct">
						{{ answerUserChoose.answer }}
					</span>
				</span>
				<div v-if="testTrack.explanation">
					<v-divider class="mt-3 mb-3"></v-divider>
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="text-left">
									<div v-html="testTrack.explanation"></div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col>
				<p class="mb-0">
					Bonne réponse :
					<span class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct">
						{{ testTrackCorrectAnswer(testTrack).answer }}
					</span>
				</p>
				<p class="mb-0">
					Ta réponse :
					<span
						class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-incorrect ortho-questions-answers-incorrect-border"
					>
						{{ answerUserChoose ? answerUserChoose.answer : 'Pas de réponse' }}
					</span>
				</p>
				<br />
				<div v-if="testTrack.explanation">
					<v-divider class="mt-3 mb-3"></v-divider>
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="text-left">
									<div v-html="testTrack.explanation"></div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'QCMAnswerDetail',
	props: {
		testTrack: Object,
		testScore: Object,
	},
	data() {
		return {
			answerUserChoose: null,
		};
	},
	methods: {
		findAnswerUserChoose(testTrack) {
			const userAnswer = this.testScore.user_answers.find((userAnswer) => userAnswer.test_track_id === testTrack._id);
			this.answerUserChoose = userAnswer ? userAnswer.user_answers[0] : null; // Only true now since only have QCM with 1 correct answer
		},
		testTrackCorrectAnswer(testTrack) {
			const correctAnswer = testTrack.answers.find((answer) => answer.is_good_answer === true);
			return correctAnswer;
		},
	},
	created() {
		this.findAnswerUserChoose(this.testTrack);
	},
};
</script>
<style scoped>
.question-result-title {
	font-size: 30px;
}
.question-action {
	color: grey;
}
@media only screen and (max-width: 600px) {
	.question-result-title {
		font-size: 20px;
	}
}
</style>
