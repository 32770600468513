<template>
	<div id="top" class="mt20 ortho-results-container">
		<TestReportComponent
			v-if="testScore && user && testBank"
			:testBank="testBank"
			:user="user"
			:testTracks="testTracks"
			:testScore="testScore"
		/>
	</div>
</template>

<script>
import TestReportComponent from '@/components/test-report/TestReportComponent.vue';

export default {
	name: 'TestResultPage',
	components: { TestReportComponent },
	data() {
		return {
			testBank: null,
			testTracks: null,
			testScore: null,
			user: null,
		};
	},
	async mounted() {
		this.testScore = await this.$store.dispatch('testScores/fetchTestScoreById', {
			testScoreId: this.$route.params.testScoreId,
		});
		await Promise.all([
			this.fetchAndSetUser(this.testScore.user_id),
			this.fetchAndSetTestTracks(this.testScore.test_bank_id),
			this.fetchTestBank(this.testScore.test_bank_id),
		]);
	},
	methods: {
		async fetchAndSetTestTracks(testBankId) {
			this.testTracks = await this.$store.dispatch('tests/fetchAndSetTestTracks', { testBankId });
		},
		async fetchAndSetUser(userId) {
			this.user = await this.$store.dispatch('accountManager/getUserWihoutFormation', { idUser: userId });
		},
		async fetchTestBank(testBankId) {
			this.testBank = await this.$store.dispatch('tests/fetchTestBankById', { testBankId });
		},
	},
};
</script>
<style scoped></style>
