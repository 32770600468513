<template>
	<div v-if="someTracksHaveTagId === true">
		<div v-if="loading"><FamilyTagRadarChartSkeleton /></div>
		<div v-else>
			<div class="mt20 text-center hidden-sm-and-down">
				<apexchart type="radar" height="500" :options="chartOptions" :series="series" />
			</div>
			<div class="mt20 text-center hidden-md-and-up">
				<div v-for="(element, index) in series[0].data" :key="index">
					<strong class="mb-2">{{ chartOptions.xaxis.categories[index] }}</strong>
					<v-progress-linear :value="element" height="13" color="#15c39a" rounded background-color="#F1F1F1">
						<strong class="fs10">{{ element }}%</strong>
					</v-progress-linear>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import FamilyTagRadarChartSkeleton from '@/components/skeletons/FamilyTagRadarChartSkeleton';
import { defaultFamilyTagRadarChartConfig } from '@/components/reports/parcours/chartConfig.js';

export default {
	name: 'TestReportRadarChart',
	components: {
		apexchart: VueApexCharts,
		FamilyTagRadarChartSkeleton,
	},
	props: {
		testScore: Object,
		testTracks: Array,
	},
	data() {
		return {
			loading: true,
			someTracksHaveTagId: null,
			series: [{ name: 'Votre audit', data: [0, 0, 0, 0, 0] }],
			chartOptions: { ...defaultFamilyTagRadarChartConfig },
			familyTags: [],
		};
	},
	async created() {
		const testTrackHaveTagId = this.testTracks.find((testTrack) => testTrack.tag_id != null);
		if (testTrackHaveTagId != null) {
			this.someTracksHaveTagId = true;
		} else {
			this.someTracksHaveTagId = false; // Dont display radar chart when all test tracks dont have tag id
			return;
		}

		await this.fetchAndSetFamilyTagsToChartCategories();
		await this.calculateAndUpdateChartSeriesData(this.familyTags);

		this.loading = false;
	},
	methods: {
		async fetchAndSetFamilyTagsToChartCategories() {
			const familyTags = await this.$store.dispatch('familyTags/getAllFamilyTags');
			this.chartOptions.xaxis.categories = familyTags.map((tag) => tag.name);
			this.familyTags = familyTags;
		},
		async calculateAndUpdateChartSeriesData(familyTags) {
			//Get all tags id of testTracks
			const ruleTagIds = this.testTracks.map((testTrack) => testTrack.tag_id);
			const ruleTags = await this.$store.dispatch('familyTags/getRuleTagByIds', { ruleTagIds });

			// Create a Map with key is family tag name
			const familyTagNameMap = new Map();
			familyTags.forEach((tag) => {
				familyTagNameMap.set(tag.name, {
					familyTagName: tag.name,
					totalQuestions: 0,
					totalCorrectAnswers: 0,
				});
			});

			this.testTracks.forEach((testTrack) => {
				if (testTrack.tag_id == null) return;

				const trackRuleTag = ruleTags.find((ruleTag) => ruleTag._id === testTrack.tag_id);
				const familyTagOfTrack = familyTags.find((familyTag) => familyTag._id === trackRuleTag.family_tag_id);

				// Update totalQuestion of familyTag
				const familyTagValue = familyTagNameMap.get(familyTagOfTrack.name);
				familyTagValue.totalQuestions = familyTagValue.totalQuestions += 1;

				// Update correct answer of family tags
				const userAnswerOfThisTrack = this.testScore.user_answers.find(
					(userAnswer) => userAnswer.test_track_id === testTrack._id
				);
				if (userAnswerOfThisTrack == null) return; // user didn't answer so cant be correct
				if (
					userAnswerOfThisTrack.user_answers &&
					userAnswerOfThisTrack.user_answers[0] &&
					userAnswerOfThisTrack.user_answers[0].is_good_answer === true
				) {
					const familyTagValue = familyTagNameMap.get(familyTagOfTrack.name);
					familyTagValue.totalCorrectAnswers = familyTagValue.totalCorrectAnswers += 1;
				}
			});

			// Calculate chart series value to match categories order
			const seriesValue = [];
			this.chartOptions.xaxis.categories.forEach((category) => {
				const familyTagValue = familyTagNameMap.get(category);

				let chartValueOfCategory = 0;
				if (familyTagValue.totalQuestions === 0) chartValueOfCategory = 0;
				else {
					chartValueOfCategory = (familyTagValue.totalCorrectAnswers / familyTagValue.totalQuestions) * 100;
				}
				seriesValue.push(Math.ceil(chartValueOfCategory));
			});
			this.series[0].data = seriesValue; // Update to state
		},
	},
};
</script>
<style scoped></style>
