<template>
	<div v-if="testTracks">
		<h2 class="mb10">Tes réponses</h2>
		<img src="@/assets/icons/arrow-down.svg" class="arrow-icon mb20" />
		<v-row v-for="(i, index) in testTracks.length" v-bind:key="i">
			<v-col xl="12" md="12" sm="12" cols="12">
				<QCMAnswerDetail :testTrack="testTracks[index]" :testScore="testScore" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import QCMAnswerDetail from './QCMAnswerDetail.vue';
export default {
	name: 'UserAnswersDetail',
	components: { QCMAnswerDetail },
	props: {
		testTracks: Array,
		testScore: Object,
	},
	mounted() {
		document.addEventListener('copy', (event) => {
			event.preventDefault();
			alert('La copie est désactivée sur cette page.');
		});
	},
	methods: {
		findAnswerUserChoose(testTrack) {
			const userAnswer = this.testScore.user_answers.find((userAnswer) => userAnswer.test_track_id === testTrack._id);
			console.log('📢[UserAnswersDetail.vue:113]: userAnswer.user_answers[0]: ', userAnswer.user_answers[0]);
			return userAnswer.user_answers[0]; // Only true now since only have QCM with 1 correct answer
		},
	},
};
</script>
<style scoped>
.arrow-icon {
	width: 2%;
	color: #15c39a !important;
}
</style>
